import React from 'react';
import Header from '../../Header/header';
import HeaderMenu from '../../Header/header-menu';
import Footer from '../../Footer/Footer';
import ISI from '../../ISI/Isi';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export default function () {
    const navigate = useNavigate();
    return (
        <div>
            <Helmet>
                <title>
                Thank You, Your Language Preference Has Been Updated I INBRIJA® (levodopa inhalation powder)
                </title>
            </Helmet>
            <Header />
            <HeaderMenu>
                <div className='pb-8'>
                </div>
            </HeaderMenu>
            <div className="brush-style no-hero-section-brush">
            </div>
            <div className='container'>
            <div className='thankyou-espanol'>
                <h1>Thank you</h1>
                <h2>Your language preferences have been updated.</h2>
                <p>To change your language back, please <span><a href='/language-thank-you-espanol'>click here</a></span>.</p>
            </div>
            </div>
            <ISI />
            <Footer code="06/23 INB13297" year="2023"
             twitterCode="https://twitter.com/intent/tweet?text=Learn%20more%20about%20INBRIJA.%20INBRIJA%20is%20inhaled%20levodopa.%20Ask%20your%20doctor%20to%20learn%20more.%20https://www.inbrija.com"
             facebookLink="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.inbrija.com%2F&title=Learn%20more%20about%20INBRIJA&description=INBRIJA%20is%20inhaled%20levodopa.%20Ask%20your%20doctor%20to%20learn%20more."
            />
        </div>
    )
}