import React from "react";
import { Link, useNavigate } from "react-router-dom";
import './NavigationBar.css'

export default function NavigationBar({ leftNavigation, leftNavigationLink, rightNavigation, rightNavigationLink, target, leftNavigationid, rightNavigationid }) {
    const navigate = useNavigate();
    return (
        <div className="navigation-bar">
            <div className="row">
                <div className="col-md-6">
                    <Link to={leftNavigationLink} id={leftNavigationid}> {leftNavigation} </Link>
                </div>
                <div className="col-md-6">
                    {target ? <a className="nav-links" target={target ? '_blank' : ''} href={rightNavigationLink}> {rightNavigation} </a> :
                        <Link to={rightNavigationLink} id={rightNavigationid}> {rightNavigation} </Link>}
                </div>
            </div>
        </div>
    )
}