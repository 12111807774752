import React, { useState, useEffect, useRef } from "react";
import Header from '../../Header/header';
import HeaderMenu from '../../Header/header-menu';
import Footer from '../../Footer/Footer';
import Isi from '../../ISI/Isi';
import IsiFrame from '../../ISI/Isi-frame';
import { Link } from "react-router-dom";
import './Site-map.css';
import { Helmet } from "react-helmet";
import MainLayout from "../../Layouts/Main_Layout";

export default function SiteMap() {

  const myRef = useRef(null)
  const [show, setShow] = useState(true)
  const executeScroll = () => {
    myRef.current.scrollIntoView();
    setShow(true)
  }
  useEffect(() => {
    function handleScroll() {
      ;
      if (window.innerHeight + window.scrollY > 1200) {
        setShow(false);
      }
      else (
        setShow(true)
      )
    }
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [show]);
  return (
    <MainLayout>
      <div className="site-map-page">
        <Helmet>
          <title>Sitemap | INBRIJA® (levodopa inhalation powder)</title>
          <meta property='og:title' content={`Sitemap | INBRIJA® (levodopa inhalation powder)`} />
          <meta property='og:site_name' content='INBRIJA.COM' />
          <meta property='og:type' content='website' />
          <meta property="og:image" content="https://inbrija.com/assets/images/Inbrija-OG-Image.jpg" />
          <meta property='og:url' content='https://inbrija.com/sitemap' />
          <meta property='og:image:alt' content='INBRIJA logo' />
        </Helmet>
        <Header />
        <HeaderMenu>
          <div className="center">
            <div className="container hero-section">
              <div className="heading">Site map</div>
            </div>
          </div>
        </HeaderMenu>
        <div className="brush-style"></div>
        <div className="container spacing site-map-cont">
          <div className="width">
            <Link className="pointer content" to="/">Home Page</Link>
            <div className="margin">
              <Link className="content" to="/what-are-off-periods">What Are OFF Periods?</Link>
            </div>
            <ul className="margin">
              <li className="site-link"> <Link className="content" to="/what-are-off-periods#return-symptoms">Return of PD Symptoms</Link></li>
              <li className="site-link"><Link className="content" to="/what-are-off-periods#experiencing-off">Experiencing OFF Periods</Link></li>
            </ul>
            <div className="margin">
              <Link className="content" to="/about-inbrija">About INBRIJA</Link>
            </div>

            <ul className="margin">
              <li className="site-link"><Link className="content" to="/about-inbrija#what-is-inbrija">What Is INBRIJA?</Link></li>
              <li className="site-link"><Link className="content" to="/about-inbrija#talking-with-doctor">Talking With Your Doctor</Link></li>
            </ul>
            <div className="margin"><Link className="content" to="/how-to-use-inbrija#when-to-us">How to Use INBRIJA</Link></div>
            <ul className="margin">
              <li className="site-link"><Link className="content" to="/how-to-use-inbrija#when-to-use">When to Use INBRIJA</Link></li>
              <li className="site-link"><Link className="content" to="/how-to-use-inbrija#learn-how-use-inbrija">Learn How to Use INBRIJA</Link></li>
              <li className="site-link"><Link className="content" to="/how-to-use-inbrija#nurse-educator-support">Nurse Educator Support</Link></li>
              <li className="site-link"><Link className="content" to="/how-to-use-inbrija#helpful-hints">Helpful Hints</Link></li>
            </ul>
          </div>
          <div className="width">
            <div className=""><Link className="content" to="/savings-and-support">Savings & Support</Link></div>
            <ul className="margin">
              <li><Link className="content" to="/savings-and-support#savings-programs">Savings Programs</Link></li>
              <li><Link className="content" to="/savings-and-support#prescription-support">Prescription Support Services</Link></li>
            </ul>
            <div className="margin"><Link className="content" to="/contact-us">Contact Us</Link></div>
          </div>
        </div>
        <div ref={myRef}>
          <Isi />
        </div>
        {show ?
          <IsiFrame onClick={executeScroll} /> : ''}
        <Footer code="06/23 INB13297" year="2023" />
      </div>
    </MainLayout>
  )
}