import React from 'react';
import Header from '../../Header/header';
import HeaderMenu from '../../Header/header-menu';
import Footer from '../../Footer/Footer';
import ISI from '../../ISI/Isi';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export default function () {
    const navigate = useNavigate();
    return (
        <div id='thankyou-page'>
            <Helmet>
                <title>
                Thank You For Registering!
                </title>
            </Helmet>
            <Header />
            <HeaderMenu>
                <div className='thankyou-menu-padding'>
                </div>
            </HeaderMenu>
            <div className="brush-style">
            </div>
            <div className='thankyou'>
                <h1>Thank you</h1>
                <p>You're now registered to receive updates.</p>
                <button onClick={() => navigate('/')}>Go to Home Page</button>
                <div className='thankyou-contact'>
                    Have questions? <br /> Contact us at <b className='thankyou-contact-number'>1-833-INBRIJA</b>
                </div>
            </div>
            <ISI />
            <Footer code="12/23 INB13467" year="2023"
             twitterCode="https://twitter.com/intent/tweet?text=Learn%20more%20about%20INBRIJA.%20INBRIJA%20is%20inhaled%20levodopa.%20Ask%20your%20doctor%20to%20learn%20more.%20https://www.inbrija.com"
             facebookLink="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.inbrija.com%2F&title=Learn%20more%20about%20INBRIJA&description=INBRIJA%20is%20inhaled%20levodopa.%20Ask%20your%20doctor%20to%20learn%20more." />
        </div>
    )
}