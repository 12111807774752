import React, { useState, useRef, useEffect } from 'react';
import HeaderMenu from "../../Header/header-menu";
import Header from "../../Header/header";
import Footer from "../../Footer/Footer";
import Isi from "../../ISI/Isi";
import IsiFrame from "../../ISI/Isi-frame";
import './Saving-and-support.css'
import { Link, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import MainLayout from '../../Layouts/Main_Layout';

export default function SavingSupport() {
  const location = useLocation();
  const [saving, setSaving] = useState(false)
  const [support, setSupport] = useState(false)
  const supportRef = useRef(null)
  const myRef = useRef(null)
  const submenu = useRef(null)
  const [show, setShow] = useState(true)

  const executeScroll = () => {
    var element = myRef.current;
    var headerOffset = 39;
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    if (getChromeVersion() > 60) {
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    }
    else {
      window.scrollTo(0, offsetPosition);
    }
    setShow(true)
  }
  const [imagesLoaded, setImagesLoaded] = useState(false);

  const handleImageLoads = () => {
    // Check if all images have finished loading
    const allImagesLoaded = Array.from(document.querySelectorAll('img')).every((image) => image.complete);

    if (allImagesLoaded) {
      setImagesLoaded(true);
    }
  };

  const getChromeVersion = () => {
    var raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);
    if (raw) {
      return parseInt(raw[2], 10);
    }
    else {
      return 100;
    }
  }

  function handleAboutCSS() {
    if (document.getElementById('savings-and-support-page')) {
      var element = document.getElementById('savingRef');
      var offsetTop1 = element.offsetTop - 100;
      var element2 = document.getElementById('supportRef');
      var offsetTop2 = element2.offsetTop - 100;
      if (window.scrollY > offsetTop1) {
        document.getElementById('saving-link').classList.add('submenu-style-active');
      }
      if (window.scrollY > offsetTop2) {
        document.getElementById('saving-link').classList.remove('submenu-style-active');
        document.getElementById('saving-link').classList.add('submenu-style-not-active');
      }

      if (window.scrollY > offsetTop2) {
        document.getElementById('support-link').classList.add('submenu-style-active');
      }
      else {
        document.getElementById('support-link').classList.remove('submenu-style-active');
        document.getElementById('support-link').classList.add('submenu-style-not-active');
      }
    }
  }

  useEffect(() => {
    document.addEventListener('click', function (e) {
      if (e.target.closest('a') || e.target.closest('button')) {
        if (document.location.pathname.includes('/what-are-off-periods')) {
          window.gtag('event', 'what_are_off_periods_buttons');
        }
        if (document.location.pathname.includes('/about-inbrija')) {
          window.gtag('event', 'about_inbrija_buttons');
        }
        if (document.location.pathname.includes('/how-to-use-inbrija')) {
          window.gtag('event', 'how_to_use_inbrija_buttons');
        }
        if (document.location.pathname.includes('/savings-and-support')) {
          window.gtag('event', 'savings_and_support_buttons');
        }
      }
    });
    handleAboutCSS();
    if (location.hash === '#savings-programs') {
      var element = document.getElementById('savingRef');
      var headerOffset = 45;
      var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      if (getChromeVersion() > 60) {
        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth"
        });
      }
      else {
        window.scrollTo(0, offsetPosition);
      }
      setSaving(true);
      setSupport(false);
    }

    if ((location.hash === '#prescription-services') || (location.hash === '#prescription-support')) {
      var element = document.getElementById('supportRef');
      var headerOffset = 45;
      var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      if (getChromeVersion() > 60) {
        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth"
        });
      }
      else {
        window.scrollTo(0, offsetPosition);
      }
      setSaving(false);
      setSupport(true);
    }
    if (location.pathname === '/savings-and-support' || location.pathname === '/savings-and-support/') {
      var windowHeight = window.innerHeight;
      var header = document.getElementById('submenu');

      window.addEventListener('scroll', function () {
        var scrollPosition = window.pageYOffset || document.documentElement.scrollTop;

        if (scrollPosition > windowHeight) {
          header.classList.add('sticky');
        } else {
          header.classList.remove('sticky');
        }
      });
    }
    const calculateOffset = () => {
      const componentElement = myRef.current;

      if (myRef) {
        const offsetTop = componentElement.offsetTop;
        function handleScroll() {
          if (window.innerHeight + window.scrollY > offsetTop) {
            setShow(false);
          }
          else (
            setShow(true)
          )
        }

        window.addEventListener("scroll", handleScroll);
        window.addEventListener("scroll", handleAboutCSS);

        return () => {
          window.removeEventListener("scroll", handleScroll);
          window.removeEventListener("scroll", handleAboutCSS);
        };
      }
    };
    calculateOffset();
    window.addEventListener('resize', calculateOffset);

    const handleHashChange = () => {
      // Reset the images loaded status
      setImagesLoaded(false);
    };

    window.addEventListener('hashchange', handleHashChange);

    const metaTags = [];

    // Define the metadata
    const metadata = [
      { property: 'og:title', content: `Savings and Support | INBRIJA® (levodopa inhalation powder)` },
      { property: 'og:description', content: `Explore what savings options are available and how Prescription Support Services can assist you after receiving an INBRIJA prescription. Limitations and restrictions apply. See Indication, Important Safety Information, and Patient Information Leaflet.` },
      { property: 'og:site_name', content: 'INBRIJA.COM' },
      { property: 'og:type', content: 'website' },
      { name: 'description', content: `Explore what savings options are available and how Prescription Support Services can assist you after receiving an INBRIJA prescription. Limitations and restrictions apply. See Indication, Important Safety Information, and Patient Information Leaflet.` },
    ];

    // Iterate over the metadata and create meta tags
    metadata.forEach((meta) => {
      const metaTag = document.createElement('meta');
      if (meta.name) {
        metaTag.setAttribute('name', meta.name);
      }
      if (meta.property) {
        metaTag.setAttribute('name', meta.property);
      }
      metaTag.setAttribute('content', meta.content);
      metaTags.push(metaTag);
    });

    // Get the <head> element
    const head = document.head;

    const titleTag = head.querySelector('title');
    // Insert the meta tags before the first child of the <head> element
    metaTags.forEach((metaTag) => {
      head.insertBefore(metaTag, titleTag.nextSibling);
    });


    return () => {
      window.removeEventListener('resize', calculateOffset);
      window.removeEventListener('hashchange', handleHashChange);
      metaTags.forEach((metaTag) => {
        head.removeChild(metaTag);
      });
    };
  }, [location, imagesLoaded]);

  if (imagesLoaded) {
    var windowHeight = window.innerHeight;
    var header = document.getElementById('submenu');

    window.addEventListener('scroll', function () {
      var scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
      const offsetTop = header.offsetTop;
      if (scrollPosition > offsetTop) {
        header.classList.add('sticky');
      } else {
        header.classList.remove('sticky');
      }
    });
  }

  return (
    <MainLayout>
      <div className='saving-and-support-page' id='savings-and-support-page'>
        <Helmet>
          <title>Savings and Support | INBRIJA® (levodopa inhalation powder)</title>
          <meta property='og:title' content={`Savings and Support | INBRIJA® (levodopa inhalation powder)`} />
          <meta property='og:description' content={`Explore what savings options are available and how Prescription Support Services can assist you after receiving an INBRIJA prescription. Limitations and restrictions apply. See Indication, Important Safety Information, and Patient Information Leaflet.`} />
          <meta property='og:site_name' content='INBRIJA.COM' />
          <meta property='og:type' content='website' />
          <meta name='description' content={`Explore what savings options are available and how Prescription Support Services can assist you after receiving an INBRIJA prescription. Limitations and restrictions apply. See Indication, Important Safety Information, and Patient Information Leaflet.`} />
          <meta property="og:image" content="https://inbrija.com/assets/images/Inbrija-OG-Image.jpg" />
          <meta property='og:url' content='https://inbrija.com/savings-and-support' />
          <meta property='og:image:alt' content='INBRIJA logo' />
        </Helmet>
        <Header />
        <HeaderMenu>
          <div className='hero-section-saving'>
            <div className="container-1500">
              <div className='hero-section'>
                <div className='hero-section-image'>
                  <img onLoad={handleImageLoads} src="/assets/images/saving-herodesktop-img.webp" className='img-fluid desktop-image' alt={`Someone zipping up a coat with the words "I won't give up" on the chest."`} loading='lazy' />
                  <div className="actor_portrayal">Actor portrayal.</div>
                </div>
                <div className='hero-section-content'>
                  <h4>Savings with INBRIJA</h4>
                  <h1>Merz is <span>committed to helping</span> patients access affordable <br className='br-overscreen' />treatment with INBRIJA</h1>
                </div>
              </div>
            </div>
          </div>
        </HeaderMenu>
        <div className='menu-bar-sticky-desktop'>
          <div className="brush-style-2" id="submenu" ref={submenu}>
            <div className="submenu-style">
              <Link className='text-decoration-none' to="/savings-and-support#savings-programs"> <div id='saving-link' className={saving ? 'submenu-style-active pointer' : 'submenu-style-not-active pointer'}>Savings Programs</div></Link>
              <Link className='text-decoration-none' to="/savings-and-support#prescription-services">  <div id='support-link' className={support ? 'submenu-style-active pointer' : 'submenu-style-not-active pointer'}>Prescription Support Services</div></Link>
            </div>
          </div>
        </div>
        <div className='menu-bar-sticky-mobile'>
          <div className='mobile-header-sticky brush-style-2'></div>
        </div>
        <div className='saving-program-container' id='savingRef'>
          <div className='saving-program-container-title-section saving-container'>
            <div className='container'>
              <h2>If you or someone you care for want to know how they can save when starting INBRIJA, there are options.</h2>
              <p>Below are the different savings programs that are offered to help you when starting INBRIJA.</p>
            </div>
          </div>
          <div className='saving-program-container-content-section'>
            <div className='container'>
              <ul>
                <li>
                  <div className='saving-program-container-content-section-image'>
                    <img onLoad={handleImageLoads} src='/assets/images/pay-programs.webp' alt='RX illustration' className='img-fluid' />
                    <h3 className='show_mobile'>$0 Co-Pay Program and Ongoing Assistance</h3>
                  </div>
                  <div className='saving-program-container-content-section-text'>
                    <h3 className='show_desktop'>$0 Co-Pay Program and Ongoing Assistance</h3>
                    <p>You may be eligible to receive your first prescription of INBRIJA—up to five 30-dose cartons—free with a $0 co-pay.*</p>
                    <p>Prescription Support Services can confirm whether your insurance will cover your prescription and whether you are eligible for patient co-pay savings. If you have commercial insurance (employer-sponsored or individual) and INBRIJA is covered, you may be eligible to receive INBRIJA for as little as $30 per month, where allowed by law. Patients who participate in Medicaid, Medicare, or any other US federal healthcare program are not eligible to receive co-pay assistance.</p>
                  </div>
                </li>
                <li>
                  <div className='saving-program-container-content-section-image'>
                    <img onLoad={handleImageLoads} src='/assets/images/asistant-program.webp' alt='Assistance illustration' className='img-fluid' />
                    <h3 className='show_mobile'>Patient Assistance Program</h3>
                  </div>
                  <div className='saving-program-container-content-section-text'>
                    <h3 className='show_desktop'>Patient Assistance Program</h3>
                    <p>If you cannot afford INBRIJA, Prescription Support Services may be able to help. You may be eligible for the Patient Assistance Program, which is designed to help you get INBRIJA at no cost. Ask us for more information about the program and how to apply.</p>
                  </div>
                </li>
                <li>
                  <div className='saving-program-container-content-section-image'>
                    <img onLoad={handleImageLoads} src='/assets/images/help-program.webp' alt='First aid kit illustration' className='img-fluid' />
                    <h3 className='show_mobile'>Medicare Extra Help Program</h3>
                  </div>
                  <div className='saving-program-container-content-section-text'>
                    <h3 className='show_desktop'>Medicare Extra Help Program</h3>
                    <p>If you are enrolled in Medicare and have limited financial resources, you may qualify for the Medicare Extra Help program to help pay for some healthcare and prescription drug costs. Ask us for details about how to apply for the program. The Medicare Extra Help program is a federal program and is not managed by Merz.</p>
                    <p className='disclaimer-text'>Limitations and restrictions apply. For more information, call 1-888-887-3447. The dispensing pharmacy will submit a claim to your insurance carrier. Merz Therapeutics, LLC. will reimburse the dispensing pharmacy in the amount of your required financial contribution as determined based on your pharmacy benefit plan.</p>
                    <div className='saving-program-container-content-section-image-call'>
                      <div className='saving-program-container-content-section-image-call'>
                        <img onLoad={handleImageLoads} src='/assets/images/call-icon.webp' alt="Phone and chat icon" className='call-icon-img' />
                      </div>
                      <div className='saving-program-container-content-section-text'>
                        <p>If you have questions about INBRIJA or would like to speak with a Nurse Educator for additional training tips, please contact Prescription Support Services directly. </p>
                        <h3 className='call'> Call toll-free <a href="tel:1-888-887-3447" className='call' id='savings_888_1'>1-888-887-3447</a></h3>
                        <p>8 <small>AM</small> to 8 <small>PM</small> Eastern Time, Monday through Friday</p>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className='prescription-support-container' ref={supportRef} id="supportRef">
          <div className='prescription-support-container-title-section'>
            <div className="container">
              <h2>Here's what to expect once your doctor prescribes INBRIJA</h2>
              <h5>Prescription Support Services is here to help</h5>
              <p>Prescription Support Services works together with your doctor and a specialty pharmacy. If you have questions about how to use INBRIJA or need assistance understanding your insurance coverage, you can talk to Prescription Support Services.</p>
              <hr />
            </div>
          </div>
          <div className='prescription-support-container-content-section'>
            <div className='container'>
              <ul>
                <li>
                  <div className='prescription-support-container-content-section-image'>
                    <img onLoad={handleImageLoads} src='/assets/images/headset.webp' alt='Headset illustration' className='img-fluid' />
                  </div>
                  <div className='prescription-support-container-content-section-text'>

                    <h3>1. You will get a call from Prescription Support Services</h3>
                    <div className='hidden-mobile'>
                      <p>After determining what kind of coverage you have, a specialist will call you to:</p>
                      <ul>
                        <li>Review coverage and financial assistance options (if you are eligible)</li>
                        <li>Ensure that you know how to use INBRIJA</li>
                      </ul>
                    </div>
                    <div className='hidden-desktop'>
                      <ul>
                        <li>After determining what kind of coverage you have, a specialist will call you to</li>
                        <li>Review coverage and financial assistance options (if eligible)</li>
                      </ul>
                    </div>
                    <div className='extra-info'>
                      <p>You must speak to the specialist who calls to get this information. Calls from your Prescription Support Services specialist will come from the phone number <a href="tel:1-888-887-3447" id='savings_888_2'>1-888-887-3447</a>.</p>
                    </div>
                  </div>
                </li>

                <li>
                  <div className='prescription-support-container-content-section-image'>
                    <img onLoad={handleImageLoads} src='/assets/images/delivery.webp' alt='Delivery truck illustration' className='img-fluid' />
                  </div>
                  <div className='prescription-support-container-content-section-text'>
                    <h3 className="show_desktop">2. You will get your INBRIJA delivery from a specialty pharmacy</h3>
                    <h3 className="show_mobile">2. You will get an INBRIJA delivery from a specialty pharmacy</h3>
                    <ul>
                      <li>A specialty pharmacy representative will call you to arrange payment details and delivery of INBRIJA</li>
                      <li>A specialty pharmacy will follow up with refills as prescribed</li>
                    </ul>
                    <div className='extra-info'>
                      <p>You must speak to the specialty pharmacy representative who calls to confirm your shipment. Calls may come from an unrecognized phone number.</p>
                    </div>
                  </div>
                </li>

                <li>
                  <div className='prescription-support-container-content-section-image'>
                    <img onLoad={handleImageLoads} src='/assets/images/support.webp' alt='Outstretched hand illustration' className='img-fluid' />
                  </div>
                  <div className='prescription-support-container-content-section-text'>
                    <h3 className='show_desktop'>3. A Nurse Educator will call you to:</h3>
                    <h3 className='show_mobile'>3. After you have received your first carton of INBRIJA along with your Start Kit, a Nurse Educator will call you to:</h3>
                    <ul>
                      <li>Review device training either by phone or connecting through a video</li>
                      <li>Review contents of your Start Kit</li>
                      <li>Schedule follow-up calls</li>
                    </ul>
                    <div className='extra-info'>
                      <p>Speaking with a Nurse Educator is highly recommended. Calls from the Nurse Education will come from <a href="tel:1-888-887-3447" id='savings_888_3'>1-888-887-3447</a>.</p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className='nurse-support-container'>
          <div className='nurse-support-contianer-wrap'>
            <div className='nurse-support-contiainer-wrap-image'>
              <img onLoad={handleImageLoads} src='/assets/images/nurse-educator.webp' alt='Head shot of nurse educator smiling' className='img-fluid' />
              <p>Actor portrayal.</p>
            </div>
            <div className='nurse-support-contiainer-wrap-content'>
              <h3>Nurse Educators are available to support you with additional training by phone or video and to answer any questions about how to use the inhaler.</h3>
              <div className="call-toll-free"><span className="content-text">You may contact them at</span><span className='content-number'><a href="tel:1-888-887-3447" className="secondry-button" id='savings_888_4'>1-888-887-3447</a><span>8 <small>AM</small> to 8 <small>PM</small> ET. <br /> Monday through Friday</span></span></div>
            </div>
          </div>
        </div>
        <div class="navigation-bar">
          <div class="row">
            <div class="col-md-12">
              <a id="savings_learn_how_use" href="/how-to-use-inbrija#learn-how-use-inbrija"> Learn How to Use INBRIJA </a>
            </div>
          </div>
        </div>
        <div ref={myRef}>
          <Isi />
        </div>
        {
          show ? <IsiFrame onClick={executeScroll} /> : ''
        }
        <Footer year="2023" code="06/23 INB13297"
          twitterCode={location.hash === '#prescription-support' || '#prescription-services' ? 'https://twitter.com/intent/tweet?text=Prescription%20Support%20Services.%20Prescription%20Support%20Services%20is%20available%20to%20help%20you!%20https://www.inbrija.com/prescription-support-services' :
            'https://twitter.com/intent/tweet?text=Savings%20Programs.%20Learn%20more%20about%20the%20savings%20programs%20that%20are%20offered%20to%20you.%20https://www.inbrija.com/savings-programs'}
          facebookLink={location.hash === '#prescription-support' || '#prescription-services' ? 'https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.inbrija.com%2Fprescription-support-services&title=Prescription%20Support%20Services&description=Prescription%20Support%20Services%20is%20available%20to%20help%20you!' :
            'https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.inbrija.com%2Fsavings-programs&title=Savings%20Programs&description=Learn%20more%20about%20the%20savings%20programs%20that%20are%20offered%20to%20you.'
          }
          title="Savings and Support | INBRIJA® (levodopa inhalation powder)"
        />
      </div>
    </MainLayout>
  );
}