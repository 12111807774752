import React from "react";
import './Brochure.css'

export default function Brochure({ src, alt, children, src1 }) {
  return (
    <div className="brochure-container">
      <div className="brochure-image ">
        <div className="brochure-image-img show_desktop">
          <img src={src1} className="" alt={alt} />
        </div>
        <div className="brochure-image-img show_mobile" style={{ background: `#4B3F67 url(${src})` }}></div>
      </div>
      <div className="image-overlay">
        {children}
      </div>
    </div>
  )
}