import React, { Children, useState } from 'react';
import { ModalHeader } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export default function ExternalLinkModal({ show, handleClose,children }) {

  return (
    <>
    <div className='center'>
      <Modal show={show} onHide={handleClose} size="xl" className='ddg-modal-class'>
        <ModalHeader className='about-inbrija-modal'></ModalHeader>
        <div className='modal-close'>
          <div className="close-modal" onClick={handleClose}>
            <div className="close-modal-left"></div>
            <div className="close-modal-right"></div>
          </div>
        </div>
        <Modal.Body className='about-inbrija-padding'>
        {children}
        </Modal.Body>
      </Modal>
      </div>
    </>
  );
}
