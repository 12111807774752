import React from "react";
import Header from '../../Header/header';
import HeaderMenu from '../../Header/header-menu';
import Footer from '../../Footer/Footer';
import ISI from '../../ISI/Isi';
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
// import '../Homepage/Homepage.css'

export default function Error() {
    const navigate = useNavigate();
    return (
        <div>
            <Helmet>
                <title>404 Page Not Found</title>
            </Helmet>
            <Header />
            <HeaderMenu>
                <div className="center">
                    <div className="container pt-9 pb-9 error">
                        <h1>404 Error</h1>
                    </div>
                </div>
            </HeaderMenu>
            <div className="brush-style"></div>
            <div className="container error-margin error">
                <h3>
                    Sorry, page not found
                </h3>
                <div className="error-padding">
                <p>
                    There seems to be a slight problem with the URL you are trying to access.
                </p>
                <p>If you think you have reached this page in error, please double check the URL and try again <br className="br-desktop" /> or you may click this link to access the <span className="text-underline" onClick={() => navigate('/')}>home page</span>.</p>
                </div>
            </div>
            <ISI />
            <Footer code="06/23 INB13297" year="2023"
             twitterCode="https://twitter.com/intent/tweet?text=Learn%20more%20about%20INBRIJA.%20INBRIJA%20is%20inhaled%20levodopa.%20Ask%20your%20doctor%20to%20learn%20more.%20https://www.inbrija.com"
             facebookLink="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.inbrija.com%2F&title=Learn%20more%20about%20INBRIJA&description=INBRIJA%20is%20inhaled%20levodopa.%20Ask%20your%20doctor%20to%20learn%20more."
            />
        </div>
    )
}