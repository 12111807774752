import React from "react";
import './header.css'

export default function Header() {
  return (
    <div className="header-container">
      <div className="header-width">
        <small className=" residence-text">For US Residents Only</small>
        <div className=" flex">
          <a href="https://inbrija-hcp.com" target="_blank" rel="noreferrer" id="HCP_outbound_header" className="website-links">US Healthcare Professionals Website </a>
          <div className="saperator">|</div>
          <a href="/patient-information.pdf" target="_blank" className="website-links-mobile patient-information" id="PI_download_header">Patient Information</a>
          <div className="saperator">|</div>
          <small className="website-links-noborder"><span className="question">Questions? </span><a href="tel://1-833-INBRIJA" className="text-decortion-ques" id="833_inbrija">1-833-INBRIJA</a></small>
        </div>
      </div>
    </div>
  )
}