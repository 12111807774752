import React, { Children, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export default function ExternalLinkModal({ show, handleClose,children,className }) {

  return (
    <>
      <Modal show={show} onHide={handleClose} dialogClassName="email-modal" centered>
        <div className='modal-close'>
          <div className="close-modal" onClick={handleClose}>
            <div className="close-modal-left "></div>
            <div className="close-modal-right"></div>
          </div>
        </div>

        <Modal.Body>
        {children}
        </Modal.Body>
      </Modal>
    </>
  );
}
